import Vue from 'vue'
import Vuex from 'vuex'

import i18n from "@/Assets/I18n"

Vue.use(Vuex)

export default new Vuex.Store({
        state: {
            base: null,
            messagerie: {},
            userId: 0,
            drafts: [],
            usersGtp: [],
            isStatusFicheDone: false,
            currentFicheId: null,
            selectedClientId: null,
            selectedBaseId: null,
            selectedClientData: {},
            clientList: {data:[]},
            baseList: {data:[]},
            basesByClientCount: {},
            basesParams: [],
            rights: {},
            configParams: false,
            paramBaseId: null,
            baseToTcheckId: null,
            basesRights: {},
            doubleAuthentification: {},
            configList: [],
            selectedConfig: null,
            fetes: [],
            msgToArchive: {},
            itemsMenu: [],
            droitSupportVision: [],
            droitTag: "",
            moduleId: null,
            sousModuleId: null,
            moduleItem: {},
            selectedDiffusionId: null,
            taskSupportVision: [],
            rightsDev: {},
            taskId: null,
            prefilledCardData: null,
            accountsSSO: [],
            informations: {
                firstname: "",
                lastname: "",
                username: "",
                emailAddress: "",
                signature: null,
                photo: null,
                administrator: false,
                phoneNumber: "",
            },
            preferences: {
                activeLogin: true,
                theme: false,
                background: "",
                lang: navigator.language.split("-")[0].toLowerCase() || "en",
                soundOn: false,
                sendMail: false,
                hotlineListView: 0,
                widgetBoard: {
                    cols: 6,
                    rows: 4
                },
                modulesView: 0,
                moduleSort: {},
                stayLogged: "",
                preselected_filters_simple: {}
            },
            authenticationConfiguration: null,
            notifications: [],
            modules: [],
            hotline: [],
            widgetBoard: {
                board: {
                    cols: 8,
                    rows: 6
                },
                widgets: [
                    {
                        name: "agenda",
                        position: {
                            x: 1,
                            y: 0
                        },
                        width: 3,
                        height: 4,
                        datas: {
                            events: [],
                            preferences: {}
                        }
                    },
                    {
                        name: "planete-online-news",
                        position: {
                            x: 0,
                            y: 0
                        },
                        width: 1,
                        height: 4,
                        datas: {
                            news: [],
                            preferences: {},
                            seen: []
                        }
                    },
                    {
                        name: "tasks",
                        position: {
                            x: 4,
                            y: 0
                        },
                        width: 2,
                        height: 2,
                        datas: {
                            tasks: [],
                            preferences: {
                                showCompletedTasks: true
                            }
                        }
                    },
                    {
                        name: "modules",
                        position: {
                            x: 4,
                            y: 2
                        },
                        width: 2,
                        height: 2,
                        datas: {
                            modules: {
                                starred: [],
                                recurrent: [],
                            },
                            preferences: {}
                        }
                    }
                ]
            },
            communication: {
                news: [],
                exclusionLists: []
            }
        },
        mutations: {
            addDraft(state, payload) {
              let newDraft = {
              ficheId: payload.ficheId,
              draft: payload.draft
              };
              const existingDraftIndex = state.drafts.findIndex(a => a.ficheId === payload.ficheId);

              if (existingDraftIndex !== -1) {
                // Si un brouillon existe déjà pour cette fiche, le remplacer par le nouveau brouillon
                state.drafts.splice(existingDraftIndex, 1, { ficheId: payload.ficheId, draft: payload.draft });
              } else {
                // Sinon, ajouter un nouveau brouillon pour cette fiche
                state.drafts.push({ ficheId: payload.ficheId, draft: payload.draft });
              }
            },
            removeDraft(state, payload) {
              let index = state.drafts.findIndex(a => a.ficheId == payload.ficheId);
              state.drafts.splice(index, 1);
            },
            setCurrentFicheId(state, payload) {
              state.currentFicheId = payload;
            },
            setStatusFicheDone(state, payload) {
              // si je suis sur la fiche courante
              if (state.currentFicheId == payload.ficheId) {
                state.isStatusFicheDone = payload.status;
              }
            },
            setSelectedClientId(state, clientId) {
              state.selectedClientId = clientId;
            },
            setSelectedBaseId(state, baseId) {
              state.selectedBaseId = baseId;
            },
            setClientList(state, payload) {
              state.clientList = payload;
            },
            setBaseList(state, payload) {
              state.baseList = payload;
            },
            setBasesByClientCount(state, payload) {
              state.basesByClientCount = payload;
            },
            setBasesParams(state, payload) {
                state.basesParams = payload;
            },
            setRights(state, payload) { 
                const userMail = payload.user.emailAddress;
                const directionMail = payload && payload.params && payload.params.directionMail ? payload.params.directionMail : [];
                const sysAdminMail = payload && payload.params && payload.params.sysAdminMail ? payload.params.sysAdminMail : [];
                const callCenterMail = payload && payload.params && payload.params.callCenterMail ? payload.params.callCenterMail : [];
                const equipeTechniqueMail = payload && payload.params && payload.params.equipeTechniqueMail ? payload.params.equipeTechniqueMail : [];
      
                state.rights = {
                  sysAdmin: sysAdminMail.includes(userMail),
                  direction: directionMail.includes(userMail),
                  callCenter: callCenterMail.includes(userMail),
                  equipeTech: equipeTechniqueMail.includes(userMail),
                };
                this.commit("setConfigParams", (Object.keys(payload.params).length>0 && payload.params.directionMail.length>0) ? false : true);
              
            },
            setRightsDev(state, payload) {
                state.rightsDev = payload
            },
            setConfigParams(state, payload) {
                state.configParams = payload
            },
            setUsersGtp(state, payload) {
                state.usersGtp = payload;
            },
            setParamBaseId(state, payload) {
                state.paramBaseId = payload;
            },
            setBasesRights(state, payload) {
                state.basesRights = payload;
            },
            setBaseToTcheckId(state, payload) {
                state.baseToTcheckId = payload;
            },
            resetStatusFicheDone(state) {
              state.isStatusFicheDone = false;
            },
            setFetes(state, payload) { 
                state.fetes = payload
            },
            setItemsMenu(state, payload) {
                state.itemsMenu = payload
            },
            setMessagerieData(state, payload) {
                state.messagerie = payload
            },
            setDoubleAuthentification(state, payload) {
                state.doubleAuthentification = payload
            },
            setConfigList(state, payload) {
                state.configList = payload
            },
            setSelectedConfig(state, payload) {
                state.selectedConfig = payload
            },
            setDroitSupportVision(state, payload) {
                state.droitSupportVision = payload
            },
            setDroitTag(state, payload) {
                state.droitTag = payload
            },
            setModuleId(state, payload) {
                state.moduleId = payload
            },
            setSousModuleId(state, payload) {
                state.sousModuleId = payload
            },
            setModuleItem(state, payload) {
                state.moduleItem = payload
            },
            setSelectedDiffusionId(state, payload) {
                state.selectedDiffusionId = payload
            },
            setTaskSupportVision(state, payload) {
                state.taskSupportVision = payload
            },
            setTaskId(state, payload) {
                state.taskId = payload
            },
            setPrefilledCardData(state, data) {
                state.prefilledCardData = data;
            },
            setAccountsSSO(state, payload) {
                state.accountsSSO = payload
            },
            resetExclusionListNews(state, payload) {
                if (!state.communication) {
                    Vue.set(state, "communication", {news: [], exclusionLists: []})
                }
                state.communication.exclusionLists = payload
            },
            resetNews(state, payload) {
                if (!state.communication) {
                    Vue.set(state, "communication", {news: [], exclusionLists: []})
                }
                state.communication.news = payload
            },
            setInformations(state, payload) {
                state.informations = payload
            },
            setMsgToArchive(state, payload) {
                state.msgToArchive = payload
            },
            setAlertRights(state, payload) {
                if (!state.alertes) {
                    Vue.set(state, "alertes", {})
                }
                if (!state.alertes.poseSdp) {
                    Vue.set(state.alertes, "poseSdp", false)
                }
                if (!state.alertes.interim) {
                    Vue.set(state.alertes, "interim", false)
                }
                if (!state.alertes.commandes) {
                    Vue.set(state.alertes, "commandes", false)
                }
                if (!state.alertes.matriculePaie) {
                    Vue.set(state.alertes, "matriculePaie", false)
                }
                if (!state.alertes.rapport) {
                    Vue.set(state.alertes, "rapport", false)
                }
                if (!state.alertes.video) {
                    Vue.set(state.alertes, "video", false)
                }
                if (!state.alertes.listContrats) {
                    Vue.set(state.alertes, "listContrats", false)
                }
                if (!state.alertes.nouvelFiche) {
                    Vue.set(state.alertes, "nouvelFiche", false)
                }
                if (payload.rights) {

                    if (payload.rights.Non_traite_site_personnel) {
                        state.alertes.poseSdp = payload.rights.Non_traite_site_personnel == "1"
                    }
                    if (payload.rights.notification_Interim) {
                        state.alertes.interim = payload.rights.notification_Interim == "1"
                    }
                    if (payload.rights.notification_commandes) {
                        state.alertes.commandes = payload.rights.notification_commandes == "1"
                    }
                    if (payload.rights.notification_matricule_paie) {
                        state.alertes.matriculePaie = payload.rights.notification_matricule_paie == "1"
                    }
                    if (payload.rights.Afficher_rapports_import) {
                        state.alertes.rapport = payload.rights.Afficher_rapports_import == "1"
                    }
                    if (payload.rights.list_contrats) {
                        state.alertes.listContrats = payload.rights.list_contrats == "1"
                    }
                    if (payload.rights.list_candidats) {
                        state.alertes.listCandidats = payload.rights.list_candidats == "1"
                    }
                    if (payload.rights.video) {
                        state.alertes.video = payload.rights.video
                    } 
                    if (payload.rights.nouvelle_fiche) {
                        state.alertes.nouvelFiche = payload.rights.nouvelle_fiche
                    }
                    
                } else {
                    state.alertes = {
                        poseSdp: false,
                        interim: false,
                        commandes: false,
                        matriculePaie: false,
                        rapport: false,
                        listContrats: false,
                        listCandidats: false,
                        video: false,
                        nouvelFiche: false
                    }
                }
            },
            loadingLanguage(state, payload) {
                if (!state.commons) {
                    Vue.set(state, "commons", {})
                }
                if (!state.commons.loadingLanguage) {
                    Vue.set(state.commons, "loadingLanguage", payload)
                }
                state.commons.loadingLanguage = payload

            },
            setPreselectedFilter(state, payload) {
                if (state.preferences.preselected_filters == null) {
                    Vue.set(state.preferences, "preselected_filters", [])
                }
                state.preferences.preselected_filters = payload
            },
            setPreselectedFilterSimple(state, payload) {
                if (!state.preferences.preselected_filters_simple) {
                    Vue.set(state.preferences, "preselected_filters_simple", {})
                }
                state.preferences.preselected_filters_simple = payload
            },
            setDefaultView(state, payload) {
                if (state.preferences.default_view == null) {
                    Vue.set(state.preferences, "default_view", [])
                }
                state.preferences.default_view = payload
            },
            switchHotlineListView(state, payload) {
                if (!state.preferences.hotlineListView) {
                    Vue.set(state.preferences, "hotlineListView", 0)
                }
                state.preferences.hotlineListView = payload
            },
            changeBackground(state, payload) {
                if (payload) {
                    state.preferences.background = payload
                }
            },
            setWidgetBoardCols(state, payload) {
                if (!state.preferences.widgetBoard) state.preferences.widgetBoard = {cols: 6}
                state.preferences.widgetBoard.cols = payload.cols
            },
            setWidgetBoardRows(state, payload) {
                if (!state.preferences.widgetBoard) state.preferences.widgetBoard = {rows: 6}
                state.preferences.widgetBoard.rows = payload.rows
            },
            setConfig(state, payload) {
                state.authenticationConfiguration = payload
            },
            switchModulesView(state, payload) {
                state.preferences.modulesView = payload.val
            },
            sortModules(state, payload) {
                if (state.preferences.moduleSort == undefined) {
                    Vue.set(state.preferences, "moduleSort", {})
                }
                Vue.set(state.preferences, 'moduleSort', payload.val)
            },
            toggleSound(state, payload) {
                if (state.preferences.soundOn == undefined) {
                    Vue.set(state.preferences, "soundOn", false)
                }
                Vue.set(state.preferences, 'soundOn', payload)
            },
            toggleLogin(state, payload) {
                if (state.preferences.activeLogin == undefined) {
                    Vue.set(state.preferences, "activeLogin", false)
                }
                Vue.set(state.preferences, 'activeLogin', payload)
            },
            toggleSendMail(state, payload) {
                if (state.preferences.sendMail == undefined) {
                    Vue.set(state.preferences, "sendMail", false)
                }
                Vue.set(state.preferences, 'sendMail', payload)
            },
            updateWidgetDatas(state, payload) {

                // payload = {
                //     widgetName: "agenda",
                //     dimension: "events",
                //     data: Object
                // }

                let dimension = payload.dimension.split(".")
                let index = state.widgetBoard.widgets.findIndex(a => a.name == payload.widgetName) // L'index du widget
                if (state.widgetBoard.widgets[index].datas[dimension[0]] == undefined) {
                    Vue.set(state.widgetBoard.widgets[index].datas, dimension[0], {})
                }

                let indexToUpdate
                if (Array.isArray(state.widgetBoard.widgets[index].datas[dimension[0]]))
                    indexToUpdate = state.widgetBoard.widgets[index].datas[dimension[0]].findIndex(a => a.id == payload.data.id) // L'index de la donnée dans le widget
                else if (dimension[1])
                    indexToUpdate = dimension[1]

                Vue.set(state.widgetBoard.widgets[index].datas[dimension[0]], indexToUpdate, payload.data)
            },
            addWidgetDatas(state, payload) {

                // payload = {
                //     widgetName: "agenda",
                //     dimension: "events",
                //     data: Object
                // }

                let index = state.widgetBoard.widgets.findIndex(a => a.name == payload.widgetName) // L'index du widget
                let biggestId = state.widgetBoard.widgets[index].datas[payload.dimension].map(a => +a.id).filter(a => !isNaN(a)).sort((a, b) => a - b).reverse()[0] // Le plus grand id trouvé
                payload.data.id = biggestId ? (+biggestId + 1) : 1
                state.widgetBoard.widgets[index].datas[payload.dimension].push(payload.data)
            },
            deleteWidgetDatas(state, payload) {

                // payload = {
                //     widgetName: "agenda",
                //     dimension: "events",
                //     data: Object
                // }

                let index = state.widgetBoard.widgets.findIndex(a => a.name == payload.widgetName) // L'index du widget
                let indexToDelete = state.widgetBoard.widgets[index].datas[payload.dimension].findIndex(a => a.id == payload.data.id) // L'index de la données dans le widget
                state.widgetBoard.widgets[index].datas[payload.dimension].splice(indexToDelete, 1)

            },
            setUser(state, payload) {
                if (!state.informations.signature) {
                    Vue.set(state.informations, 'signature', "")
                }
                if (!state.informations.photo) {
                    Vue.set(state.informations, 'photo', "")
                }
                if (payload.user) {
                   
                    for (let k of Object.keys(payload.user)) {
                        state.informations[k] = payload.user[k] 
                    }
                    if (state.preferences.lang == "") {
                        state.preferences.lang = payload.user.defaultLanguage.toLowerCase()
                        i18n.locale = state.preferences.lang
                    }
                }
                if (payload.signature !== undefined) {
                    state.informations.signature = payload.signature
                }
                if (payload.photo !== undefined) {
                    state.informations.photo = payload.photo
                }
                if (payload.background !== undefined) {
                    state.preferences.background = {type: "custom", value: payload.background}
                }

            },
            stayLogged(state, payload) {
                if (!state.preferences.stayLogged) {
                    Vue.set(state.preferences, "stayLogged", "")
                }
                state.preferences.stayLogged = payload.value
            },
            translate(state, lang) {
                state.preferences.lang = lang
            },
            starSubModule(state, payload) {
                let index = state.widgetBoard.widgets.findIndex(a => a.name == "modules")

                let module = state.modules.find(a => a.id == payload.moduleId)
                let submodule = module.subModules.find(a => a.id == payload.submoduleId)

                if (payload.starred) {
                    state.widgetBoard.widgets[index].datas.modules.starred.push(
                        {
                            module: payload.moduleId,
                            submodule: payload.submoduleId,
                            path: submodule.path
                        }
                    )
                } else {
                    let indexToRemove = state.widgetBoard.widgets[index].datas.modules.starred.findIndex(a => a.submodule == payload.submoduleId)
                    state.widgetBoard.widgets[index].datas.modules.starred.splice(indexToRemove, 1)
                }
            },
            newLayoutWidget(state, payload) {
                let index = state.widgetBoard.widgets.findIndex(a => a.name == payload.widgetName)
                state.widgetBoard.widgets[index].width = payload.newLayout.w
                state.widgetBoard.widgets[index].height = payload.newLayout.h
                state.widgetBoard.widgets[index].position.x = payload.newLayout.x
                state.widgetBoard.widgets[index].position.y = payload.newLayout.y
            },
            resetStore: function (state, obj) {
                if (obj.widgetBoard) {
                    let index = state.widgetBoard.widgets.findIndex(a => a.name == "tasks")
                    state.widgetBoard = Object.assign(state.widgetBoard, obj.widgetBoard)
                    if (!state.widgetBoard.widgets[index].datas.preferences) {
                        Vue.set(state.widgetBoard.widgets[index].datas, "preferences", {showCompletedTasks: true})
                    }
                }
                if (obj.preferences) {
                    state.preferences = Object.assign(state.preferences, obj.preferences)
                }
                if (obj.notifications) {
                    state.notifications = Object.assign(state.notifications, obj.notifications)
                }
                if (obj.informations) {
                    state.informations = Object.assign(state.informations, obj.informations)
                }
                if (obj.modules) {
                    state.modules = obj.modules.slice()
                }

                i18n.locale = state.preferences.lang
                // Vue.$vuetify.theme.dark = state.preferences.theme
            },
            setBase(state, payload) {
                state.base = payload.base
            },
            switchDarkMode(state, payload) {
                state.preferences.theme = payload.theme
            },
            showCompletedTasks(state, payload) {
                let index = state.widgetBoard.widgets.findIndex(a => a.name == "tasks") // L'index du widget
                state.widgetBoard.widgets[index].datas.preferences.showCompletedTasks = payload.state
            },
            ficheLiaison(state, payload) {
                if (state.hotline == undefined) {
                    Vue.set(state, "hotline", [])
                }
                state.hotline = payload.data
            },
            readCom(state, payload) {
                let index = state.widgetBoard.widgets.findIndex(a => a.name == "planete-online-news") // L'index du widget
                if (!state.widgetBoard.widgets[index].datas.seen) {
                    Vue.set(state.widgetBoard.widgets[index].datas, "seen", [])
                }
                if (state.widgetBoard.widgets[index].datas.seen.indexOf(payload.id) === -1)
                    state.widgetBoard.widgets[index].datas.seen.push(payload.id)
            },
            addNotification(state, payload) {
                if (payload.category) {
                    if (!state.notifications.find(category => category.slug == payload.category.slug)) {
                        payload.category.items = []
                        state.notifications.push(payload.category)
                    }

                    if (payload.data) {
                        let index = state.notifications.findIndex(category => category.slug == payload.category.slug)

                        if (index !== -1) {
                            if (payload.reset) {
                                state.notifications[index].items = [payload.data]
                            } else {
                                state.notifications[index].items.push(payload.data)
                            }
                        }
                    } else {
                        if (payload.reset) {
                            let index = state.notifications.findIndex(category => category.slug == payload.category.slug)
                            state.notifications[index].items = []
                        }
                    }
                }
            }
        },
        actions: {
            resetStore: function ({commit}, payload) {
                commit("resetStore", payload)
            },
            setBase: function ({commit}, payload) {
                commit("setBase", payload)
            },
        },
        getters: {
            getBase: state => () => {
                return state.base
            },
            getMessagerieData: (state) => () => {
                return state.messagerie
            },
            getWidgetDatas: (state) => (widgetName, dimension) => {
                let widgetIndex = state.widgetBoard.widgets.findIndex(a => a.name == widgetName)
                if (dimension) {
                    if (!state.widgetBoard.widgets[widgetIndex].datas[dimension])
                        Vue.set(state.widgetBoard.widgets[widgetIndex].datas, dimension, [])

                    return state.widgetBoard.widgets[widgetIndex].datas[dimension]
                } else {
                    return state.widgetBoard.widgets[widgetIndex].datas
                }
            },
            getLoadingLanguage: (state) => () => {
                return state.commons.loadingLanguages
            },
            getAlertRights: (state) => (alertName) => {
                if (state.alertes) {
                    if (state.alertes[alertName]) {
                        return state.alertes[alertName]
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            },
            currentFicheId: (state) => () => {
                return state.currentFicheId
            }
        },
        modules: {}
    }
)
